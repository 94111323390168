<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    :class="bgColorClass"
  >
    <v-row
      v-if="!courseTypes.length"
    >
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            料金情報が設定されていません。<br />
            管理画面の「HP基本設定」より各種料金を設定してください。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- コース料金 -->
    <v-row dense class=""
      v-if="courseTypes.length"
    >
      <v-col cols="12">
        <h2 class="pb-2 accent--text">
          コースと料金
        </h2>
      </v-col>

      <v-col cols="12" md="6"
        class="d-flex flex-wrap"
        v-for="type in courseTypes"
        :key="type.course_type_id"
      >
        <v-card
          class="course-card flex-grow-1"
          color="transparent"
        >
          <v-card-title class="py-3 mb-1 primary font-weight-bold">
            {{ type.course_type }}
          </v-card-title>

          <v-sheet class="pa-5 fill-height" color="secondary">
            <!-- コースの説明 -->
            <v-card-text class="mb-3 pa-0"
              v-if="type.description"
            >
              {{ type.description }}
            </v-card-text>

            <!-- 各コース種類のコース料金 -->
            <v-list class="py-0">
              <v-list-item class="mb-1 pa-0 align-stretch"
                align="center"
                v-for="course in type.courses"
                :key="course.course_id"
              >
                <v-card-text class=" accent pa-2 d-flex justify-center align-center font-weight-bold"
                  :style="{border: '1px solid ' + accent}"
                >
                  <div class="d-flex flex-column">
                    <span class="item-name">
                      {{ course.course_name }}
                    </span>
                    <span class="description text-caption" align="left">
                      {{ course.description }}
                    </span>
                  </div>
                </v-card-text>
                <v-card-text
                  class="price pa-3 d-flex align-center justify-center font-weight-bold"
                  :class="setting.font != 'serif' ? 'text-overline' : setting.font"
                  :style="{border: '1px solid ' + accent, background: primary}"
                >
                  {{ priceDisplay(course.course_charge) }}
                </v-card-text>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <!-- 補足料金 -->
    <v-row dense class="mt-10"
      v-if="miscellaneous.length"
    >
      <v-col cols="12">
        <v-card class="pa-0" color="transparent">
          <v-card-title class="py-3 mb-1 primary font-weight-bold">
            その他料金
          </v-card-title>

          <v-list class="pa-5 d-flex flex-wrap" color="secondary">
            <v-list-item
              class="mb-1 pa-0 align-stretch"
              :class="$vuetify.breakpoint.mdAndUp && 'item-md'"
              align="center"
              v-for="item in miscellaneous"
              :key="item.item_id"
            >
              <v-card-text
                class="accent pa-2 d-flex justify-center align-center font-weight-bold"
                :style="{border: '1px solid ' + accent}"
              >
                <div class="d-flex flex-column">
                  <span class="item-name">
                    {{ item.item_name }}
                  </span>
                  <span class="description text-caption" align="left">
                    {{ item.description }}
                  </span>
                </div>
              </v-card-text>
              <v-card-text
                class="price pa-3 d-flex align-center justify-center font-weight-bold text-overline"
                :style="{border: '1px solid ' + accent, background: primary}"
              >
                {{ priceDisplay(item.item_charge) }}
              </v-card-text>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- オプション料金 -->
    <v-row dense class="mt-10"
      v-if="options.length"
    >
      <v-col cols="12">
        <v-card class="pa-0" color="transparent">
          <v-card-title class="py-3 mb-1 primary font-weight-bold">
            オプション料金
          </v-card-title>

          <v-list class="pa-5 d-flex flex-wrap" color="secondary">
            <v-list-item
              class="mb-1 pa-0 align-stretch"
              :class="$vuetify.breakpoint.mdAndUp && 'item-md'"
              align="center"
              v-for="option in options"
              :key="option.option_id"
            >
              <v-card-text
                class="accent pa-2 d-flex justify-center align-center font-weight-bold"
                :style="{border: '1px solid ' + accent}"
              >
                <div class="d-flex flex-column">
                  <span class="item-name">
                    {{ option.option_name }}
                  </span>
                  <span class="description text-caption" align="left">
                    {{ option.description }}
                  </span>
                </div>
              </v-card-text>
              <v-card-text
                class="price pa-3 d-flex align-center justify-center font-weight-bold text-overline"
                :style="{border: '1px solid ' + accent, background: primary}"
              >
                {{ priceDisplay(option.option_charge) }}
              </v-card-text>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- ランク料金 -->
    <v-row dense class="mt-10"
      v-if="ranks.length"
    >
      <v-col cols="12">
        <v-card class="pa-0" color="transparent">
          <v-card-title class="py-3 mb-1 primary font-weight-bold">
            ランクによる追加料金
          </v-card-title>

          <v-list class="pa-5 d-flex flex-wrap" color="secondary">
            <v-list-item
              class="mb-1 pa-0 align-stretch"
              :class="{'item-md': $vuetify.breakpoint.mdAndUp}"
              align="center"
              v-for="rank in ranks"
              :key="rank.rank_id"
            >
              <v-card-text
                class="accent pa-2 d-flex justify-center align-center font-weight-bold"
                :style="{border: '1px solid ' + accent}"
              >
                <span class="item-name">
                  {{ rank.rank }}
                </span>
              </v-card-text>
              <v-card-text
                class="price pa-3 d-flex align-center justify-center font-weight-bold text-overline"
                :style="{border: '1px solid ' + accent, background: primary}"
              >
                {{ priceDisplay(rank.rank_charge) }}
              </v-card-text>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- 交通費 -->
    <v-row dense class="mt-10"
      v-if="travelCharges.length"
    >
      <v-col cols="12">
        <v-card class="pa-0" color="transparent">
          <v-card-title class="py-3 mb-1 primary font-weight-bold">
            交通費
          </v-card-title>

          <v-list class="pa-5 d-flex flex-wrap" color="secondary">
            <v-list-item
              class="mb-1 pa-0 align-stretch"
              align="center"
              v-for="charge in travelCharges"
              :key="charge.id"
            >
              <v-card-text
                class="pa-2 d-flex justify-center align-center font-weight-bold"
                :style="{background: accent, border: '1px solid ' + accent}"
              >
                <div class="d-flex flex-column">
                  <span class="item-name">
                    {{ charge.area_name }}
                  </span>
                  <span class="description text-caption" align="left">
                    {{ charge.description }}
                  </span>
                </div>
              </v-card-text>
              <v-card-text
                class="price pa-3 d-flex align-center justify-center font-weight-bold text-overline"
                :style="{border: '1px solid ' + accent, background: primary}"
              >
                {{ priceDisplay(charge.travel_charge) }}
              </v-card-text>
            </v-list-item>

            <v-card-text class="text-body-2 pa-0 pt-2">
              ※上記地域以外への交通費はお問い合わせください。
            </v-card-text>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- クレジット支払い -->
    <v-row dense class="mt-10"
      v-if="Object.keys(creditPayment).length"
    >
      <v-col cols="12">
        <v-sheet
          class="pa-5"
          rounded
          color="secondary"
        >
          <v-card flat>
            <v-card-title
              class="pa-0 font-weight-bold"
              :style="{color: accent}"
            >
              クレジットカード支払いをご希望の方
            </v-card-title>

            <v-divider class="my-3"></v-divider>

            <v-sheet
              color="transparent"
              v-html="insertBrTag(creditPayment.description)"
            ></v-sheet>

            <v-card-subtitle
              class="px-0 pt-7 text-h6"
              :style="{color: accent}"
            >
              <v-icon color="accent">mdi-credit-card-check-outline</v-icon>
              お支払いの手順
            </v-card-subtitle>

            <v-sheet
              v-html="insertBrTag(creditPayment.instruction)"
              color="transparent"
            ></v-sheet>

            <v-card-actions
              class="mt-7 pa-0"
            >
              <v-btn
                class="mx-auto my0 px-5"
                x-large
                color="accent"
                link
                :href="creditPayment.transaction_url"
                target="_blank"
              >
                <v-icon>mdi-credit-card-check-outline</v-icon>
                お支払いはこちら
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- 追加情報 -->
    <v-row dense class="mt-10"
      v-if="additionalInfo.length"
    >
      <v-col cols="12"
        class="my-2"
        v-for="info in additionalInfo"
        :key="info.info_id"
      >
        <v-sheet
          class="pa-5"
          rounded
          color="secondary"
        >
          <v-card flat>
            <v-card-title
              class="pa-0 font-weight-bold"
              :style="{color: accent}"
            >
              {{ info.info_title }}
            </v-card-title>

            <v-divider class="my-3"></v-divider>

            <v-sheet
              color="transparent"
              v-if="info.image_url"
            >
              <v-img
                class="my-5"
                contain
                position="center center"
                max-height="300"
                max-width="100vw"
                :src="info.image_url"
                :lazy-src="lazyload_img"
              ></v-img>
            </v-sheet>

            <v-card-text class="pa-0">
              <v-sheet
                v-html="insertBrTag(info.description)"
                color="transparent"
              ></v-sheet>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************** Script *************** -->
<!-- ************************************* -->
<script>
import { LAYZLOAD_IMG } from '@/literals.js';
import { ApiTool, AlertApiError } from '@/module.js';

export default {
  components: {
  },

  props: {
    siteApi: {
      type: String,
      required: true
    },
    siteData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    setting: {
      type: Object,
      required: true,
      default: () => ({})
    },
    menus: {
      type: Array,
      required: true,
    },
  },

  //***************************************************
  // Data
  //***************************************************
  data() {
    return {
      courseTypes: [],
      miscellaneous: [],
      ranks: [],
      options: [],
      travelCharges: [],
      creditPayment: {},
      additionalInfo: [],
      primary: this.setting.primary_color,
      accent: this.setting.accent_color,
      lazyload_img: LAYZLOAD_IMG,
      apiTool: new ApiTool(this.siteApi, this.siteData),
    }
  },

  //***************************************************
  // Computed
  //***************************************************
  computed: {
    priceDisplay() {
      return price => price === 0 ? '無料' : price.toLocaleString() + '円'
    },
    insertBrTag() {
      return str => str.replace(/\r?\n/g, '<br>')
    },
    bgColorClass() {
      if (this.setting.site_bg_image_url) {
        return ''
      }
      else if (this.setting.is_dark_theme) {
        return 'secondary darken-1'
      }
      else {
        return 'secondary lighten-1'
      }
    }
  },

  //***************************************************
  // Life cycle
  //***************************************************
  created() {
    this.getSystemData()
    .catch(error => AlertApiError(error) )
  },

  //***************************************************
  // Methods
  //***************************************************
  methods: {
    async getSystemData() {
      await Promise.all([
        this.getCourses(),
        this.getMiscellaneous(),
        this.getRanks(),
        this.getOptions(),
        this.getTravelCharges(),
        this.getCreditPayment(),
        this.getAdditionalInfo(),
      ])
    },

    // API req: コースタイプからコースの順に取得
    getCourses() {
      return this.apiTool.getRequest('course-type/').then( types => {
        if (!types || !types.length) return

        this.apiTool.getRequest('course/').then( courses => {
          if (!courses || !courses.length) return

          //各タイプに該当コースを挿入
          this.courseTypes = types.map( type => {
            const typeCourses = courses.filter( course => course.course_type_id === type.course_type_id && course.course_mins > 0 )
            type.courses = typeCourses
            return type
          })

          //延長料金の追加
          this.apiTool.getRequest('course-extension/').then( exes => {
            if (!exes || !exes.length) return

            this.courseTypes.push({
              course_type_id: 0,
              course_type: '延長料金',
              courses: exes,
            })
          })
        })
      })
    },

    // API req: その他料金
    getMiscellaneous() {
      return this.apiTool.getRequest('miscellaneous-fee/').then( results => {
        if (!results || !results.length) return

        this.miscellaneous = results
      })
    },

    // API req: ランク
    getRanks() {
      return this.apiTool.getRequest('rank/').then( results => {
        if (!results || !results.length) return

        this.ranks = results
      })
    },

    // API req: オプション
    getOptions() {
      return this.apiTool.getRequest('option/').then( results => {
        if (!results || !results.length) return

        this.options = results
      })
    },

    // API req: 交通費
    getTravelCharges() {
      return this.apiTool.getRequest('travel-charge/').then( results => {
        if (!results || !results.length) return

        this.travelCharges = results
      })
    },

    // API req: クレジット支払い
    getCreditPayment() {
      return this.apiTool.getRequest('credit-payment/').then( result => {
        if (!result || !result.description) return

        this.creditPayment = result
      })
    },

    // API req: 追加情報
    getAdditionalInfo() {
      return this.apiTool.getRequest('system-additional-info/').then( results => {
        if (!results || !results.length) return

        this.additionalInfo = results
      })
    },
  }
}
</script>

<style scoped>
.v-sheet > .v-card,
.v-list {
  background-color: transparent;
}
.course-card {
  overflow: hidden;
}
.description {
  line-height: 1.3em;
}
.item-name {
  font-size: 1.2em !important;
}
.price {
  font-size: 1.1em !important;
}
.item-md {
  flex-basis: 50%;
}
.item-md:nth-child(odd) {
  padding-right: 4px !important;
}
pre {
  font-size: 1em;
  line-height: 2em;
}
</style>
